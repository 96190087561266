
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from 'vue'
import { Classroom } from '@/dto'
import ClassroomModel from '@/models/ClassroomModel'

export default defineComponent({
  name: 'Kelas Mentor',
  data() {
    return {
      classrooms: [] as Classroom[],
      classroomsTemp: [] as Classroom[],
      search: '' as string,
      debounce: null,
      loadingMyClass: false as boolean,
    }
  },
  mounted() {
    this.getClassrooms()
  },
  methods: {
    async getClassrooms() {
      this.loadingMyClass = true
      const { data } = await ClassroomModel.getMentorClass()
      this.classroomsTemp = data
      this.classrooms = this.classroomsTemp
      this.loadingMyClass = false
    },
    debounceSearch(event: any) {
      setTimeout(() => {
        this.search = event.target.value
        // console.log(this.search)
        this.searchValue(this.search)
      }, 1000)
    },
    searchValue(string: any) {
      if (string) {
        const search = this.classroomsTemp.filter((o: any) =>
          Object.keys(o).some((k: any) =>
            o[k]
              .toString()
              .toLowerCase()
              .includes(string.toLowerCase())
          )
        )
        this.classrooms = search
        // console.log(search)
      } else {
        this.classrooms = this.classroomsTemp
      }
    },
  },
})
