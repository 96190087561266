<template>
  <div class="w-10/12 flex m-auto flex-col min-h-screen">
    <span class="font-desain font-semibold text-[#212121] text-2xl mt-4"
      >Kelas Mentor</span
    >
    <div class="my-4 relative md:mr-auto">
      <input
        @input="debounceSearch"
        type="text"
        class="h-12 w-full md:w-96 pr-5 pl-3 z-0 rounded-xl shadow-sm focus:shadow-lg outline-none focus:outline-none text-[#383838] font-solusi duration-300"
        placeholder="Cari kelas disini"
      />
      <div class="absolute top-3 right-3">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          class="w-6 h-6 items-center hover:text-gray-500 z-20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.32812 12.7031C7.20312 13.5781 8.26562 14.0156 9.51562 14.0156C10.7656 14.0156 11.8281 13.5781 12.7031 12.7031C13.5781 11.8281 14.0156 10.7656 14.0156 9.51562C14.0156 8.26562 13.5781 7.20312 12.7031 6.32812C11.8281 5.45312 10.7656 5.01562 9.51562 5.01562C8.26562 5.01562 7.20312 5.45312 6.32812 6.32812C5.45312 7.20312 5.01562 8.26562 5.01562 9.51562C5.01562 10.7656 5.45312 11.8281 6.32812 12.7031ZM15.5156 14.0156L20.4844 18.9844L18.9844 20.4844L14.0156 15.5156V14.7188L13.7344 14.4375C12.5469 15.4688 11.1406 15.9844 9.51562 15.9844C7.70312 15.9844 6.15625 15.3594 4.875 14.1094C3.625 12.8594 3 11.3281 3 9.51562C3 7.70312 3.625 6.17188 4.875 4.92188C6.15625 3.64062 7.70312 3 9.51562 3C11.3281 3 12.8594 3.64062 14.1094 4.92188C15.3594 6.17188 15.9844 7.70312 15.9844 9.51562C15.9844 11.1406 15.4688 12.5469 14.4375 13.7344L14.7188 14.0156H15.5156Z"
            fill="currentColor"
          />
        </svg>
      </div>
    </div>
    <div
      v-if="loadingMyClass"
      class="bg-white md:rounded-md p-4 m-auto w-full min-h-[70vh] flex flex-col  items-center justify-center"
    >
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="spinner"
        class="w-10 h-10 animate-spin"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill="#00446F"
          d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"
        ></path>
      </svg>
      <p class="font-solusi text-base text-[#00446F] font-semibold mt-1">
        Loading...
      </p>
    </div>
    <div
      v-else
      v-show="classrooms.length == 0"
      class="w-full flex flex-col justify-center items-center py-16 md:py-10"
    >
      <img
        src="/assets/images/mentor/empty-state.svg"
        class="w-3/4 md:w-1/3"
        alt=""
      />
      <p class="font-solusi text-[#383838] font-semibold mt-3 text-center">
        Belum ada kelas.
      </p>
    </div>
    <div
      v-show="classrooms.length > 0"
      class="grid grid-cols-1 md:grid-cols-3 gap-5 mb-20"
    >
      <div
        v-for="(classroom, i) in classrooms"
        :key="i"
        class="col-span-1 p-2 rounded-xl bg-card bg-[#F6FBFF]0 hover:bg-blue-200 duration-700 shadow-md hover:shadow-lg flex flex-col mb-1.5"
      >
        <img
          @click="
            $route.push('/mentor/classroom/' + classroom.code + '/member')
          "
          :src="
            classroom.thumbnail
              ? classroom.thumbnail
              : 'https://assets.sekolahdesain.id/classroom-thumbnail/1622094429Group%2020.png'
          "
          class="rounded-xl object-fill max-h-60 md:max-h-40 w-full cursor-pointer"
          alt=""
        />
        <div class="flex flex-col justify-between flex-grow">
          <p
            v-tooltip="{
              content: classroom.name,
            }"
            class="text-[#212121] text-sm font-solusi my-2 font-semibold"
          >
            {{ classroom.name }}
          </p>
          <div class="flex justify-end mt-2">
            <router-link
              :to="'/mentor/classroom/' + classroom.code + '/member'"
              class="font-solusi rounded-full px-4 py-2 text-white bg-[#00446F] text-xs font-semibold"
            >
              Lihat Detail
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from 'vue'
import { Classroom } from '@/dto'
import ClassroomModel from '@/models/ClassroomModel'

export default defineComponent({
  name: 'Kelas Mentor',
  data() {
    return {
      classrooms: [] as Classroom[],
      classroomsTemp: [] as Classroom[],
      search: '' as string,
      debounce: null,
      loadingMyClass: false as boolean,
    }
  },
  mounted() {
    this.getClassrooms()
  },
  methods: {
    async getClassrooms() {
      this.loadingMyClass = true
      const { data } = await ClassroomModel.getMentorClass()
      this.classroomsTemp = data
      this.classrooms = this.classroomsTemp
      this.loadingMyClass = false
    },
    debounceSearch(event: any) {
      setTimeout(() => {
        this.search = event.target.value
        // console.log(this.search)
        this.searchValue(this.search)
      }, 1000)
    },
    searchValue(string: any) {
      if (string) {
        const search = this.classroomsTemp.filter((o: any) =>
          Object.keys(o).some((k: any) =>
            o[k]
              .toString()
              .toLowerCase()
              .includes(string.toLowerCase())
          )
        )
        this.classrooms = search
        // console.log(search)
      } else {
        this.classrooms = this.classroomsTemp
      }
    },
  },
})
</script>

<style scoped></style>
